<!--
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-05-18 10:47:20
 * @LastEditors: hutian
 * @LastEditTime: 2021-08-05 20:00:21
-->
<template>
  <div class="edit-address">
    <a-modal title="修改下单时间" v-model="visible" :width="800" @ok="onConfirm" :maskClosable="false" okText="确定">
      <a-form-model ref="form" :model="rowData" :rules="formRule" :label-col="{ span: 3 }" :wrapper-col="{ span: 7 }">
        <a-form-model-item label="下单时间" prop="orderDate">
          <DatePicker :startTime.sync="rowData.orderDate"></DatePicker>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>

export default {
  name: '',
  data() {
    return {
      visible: false,
      rowData: {},
      // 表单验证
      formRule: {
        orderDate: [{ required: true, message: '请选择下单时间', trigger: 'change' }],
      },
    }
  },

  computed: {},

  created() {},

  methods: {
    isShow(row) {
      this.visible = true
      this.rowData = row
      // this.$set(this.rowData, 'provinceId', this.rowData.provinceId)
      // this.$set(this.rowData, 'cityId', this.rowData.cityId)
      // this.$set(this.rowData, 'countyId', this.rowData.countyId)
      this.$nextTick(() => {
        this.$refs.pcd.setPCD()
      })
    },

    onConfirm() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return
        }
        this.axios.post(`/api/order/order/orderInfo/editOrderInfoDate`, this.rowData).then((res) => {
          if (res.code) {
            this.visible = false
            this.$emit('getConfirmDiscount')
            this.$message.success(res.message)
          }
        })
      })
    },
  },
}
</script>

<style lang='scss' scoped>
</style>